<template>
  <el-main>
    <el-form ref="form" :rules="rules" :model="wxwork" label-width="120px">
      <el-form-item label="企业名称：" prop="name">
        <el-input v-model="wxwork.name"></el-input>
      </el-form-item>
      <el-form-item label="企业ID：" prop="corpId">
        <el-input v-model="wxwork.corpId"></el-input>
      </el-form-item>
      <el-form-item label="Secret：" prop="Secret">
        <el-input v-model="wxwork.Secret"></el-input>
      </el-form-item>
      <el-form-item label="应用AgentID" prop="agentId">
        <el-input v-model="wxwork.agentId"></el-input>
      </el-form-item>
      <el-form-item label="应用Secret：" prop="corpSecret">
        <el-input v-model="wxwork.corpSecret"></el-input>
      </el-form-item>
      <el-form-item label="logo：">
        <div class="logo">
          <el-image :src="info.logo"></el-image>
          <el-button v-if="info.logo" size="small" plain @click="downloadLogo">下载</el-button>
        </div>
        <p>下载Logo到企微创建自建应用</p>
      </el-form-item>
    </el-form>
    <div class="info">
      <div class="title">功能-接收消息-设置API接收</div>
      <div class="con">
        <span>URL：</span>
        <p>{{ info.url }}</p>
        <el-button plain @click="copyInfo($event, info.url)">复制</el-button>
      </div>
      <div class="con">
        <span>Token：</span>
        <p>{{ info.token }}</p>
        <el-button plain @click="copyInfo($event, info.token)">复制</el-button>
      </div>
      <div class="con">
        <span>EncodingAESKEY：</span>
        <p>{{ info.EncodingAESKEY }}</p>
        <el-button plain @click="copyInfo($event, info.EncodingAESKEY)">复制</el-button>
      </div>
      <div class="title" style="margin-top: 20px">开发者接口-网页授权及JS-SDK</div>
      <div class="con">
        <span>可信域名：</span>
        <p>{{ info.trust_domain }}</p>
        <el-button plain @click="copyInfo($event, info.trust_domain)">复制</el-button>
      </div>
    </div>
    <Preservation @preservation="save"></Preservation>
  </el-main>
</template>
<script>
import clipboard from '@/util/clipBoard.js';
import Preservation from '@/components/preservation';
export default {
  components: {
    Preservation,
  },
  data() {
    return {
      wxwork: {
        name: '',
        corpId: '',
        Secret: '',
        agentId: '',
        corpSecret: '',
      },
      info: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'blur',
          },
        ],
        corpId: [
          {
            required: true,
            message: '请输入corpId',
            trigger: 'blur',
          },
        ],
        Secret: [
          {
            required: true,
            message: '请输入Secret',
            trigger: 'blur',
          },
        ],
        agentId: [
          {
            required: true,
            message: '请输入应用AgentID',
            trigger: 'blur',
          },
        ],
        corpSecret: [
          {
            required: true,
            message: '请输入应用Secret',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getWxwork();
  },
  methods: {
    copyInfo(e, val) {
      clipboard(e, val);
    },
    downloadLogo() {
      let a = document.createElement('a');
      a.href = this.info.logo;
      a.target = '_blank';
      a.id = 'download';
      document.body.appendChild(a);
      a.click();
      let aDom = document.getElementById('download');
      document.body.removeChild(aDom);
    },
    getWxwork() {
      this.$axios.post(this.$api.user.wxwork.getWxwork).then(res => {
        if (res.code == 0) {
          this.info = res.result.info;
          if (res.result.wxwork) this.wxwork = res.result.wxwork;
          if (this.wxwork.aesKey) this.info.EncodingAESKEY = this.wxwork.aesKey;
          if (this.wxwork.token) this.info.token = this.wxwork.token;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.user.wxwork.updateWxwork, {
            token: this.info.token,
            aesKey: this.info.EncodingAESKEY,
            agentId: this.wxwork.agentId,
            corpId: this.wxwork.corpId,
            corpSecret: this.wxwork.corpSecret,
            Secret:this.wxwork.Secret,
            name: this.wxwork.name,
          }).then(res=>{
            if(res.code == 0){
              this.$message.success("保存成功");
            }else{
              this.$message.error(res.msg);
            }
          })
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-form {
    .logo {
      display: flex;
      align-items: flex-start;
      .el-image {
        width: 100px;
        height: 100px;
        margin-right: 20px;
      }
    }

    .el-input {
      width: 400px;
      margin-right: 15px;
    }
    p {
      font-size: 12px;
      color: #636368;
    }
  }
  .info {
    .title {
      font-size: 14px;
      color: #9a9a9f;
      font-weight: 600;
      border-bottom: 1px solid #e7e7ed;
      padding-bottom: 10px;
    }
    .con {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-top: 15px;
      span {
        color: #606266;
        width: 150px;
        text-align: right;
      }
      p {
        width: 360px;
        margin-right: 15px;
      }
    }
  }
}
</style>
